/* eslint-disable import/no-anonymous-default-export */
export default {
	styleOverrides: {
		groupedHorizontal: {
			'&:not(:last-child)': {
				borderRight: 0,
			},
		},
	},
};
