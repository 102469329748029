/* eslint-disable import/no-anonymous-default-export */

export default {
	styleOverrides: {
		root: {
			top: '30px!important',
			'& .MuiPaper-root': {
				borderRadius: 4,
				maxWidth: '300px',
			},
		},
	},
};
